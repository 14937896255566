<template>
  <div style="padding: 10px">
    <div style="background: #fff; border-radius: 8px; padding: 20px">
      <div class="query-c">
         <Form  :label-width="80"  inline>
        <!-- <FormItem label="用户名">
          <Input  v-model="sevo.userName" clearable placeholder="请输入用户名" style="width: 150px" />
        </FormItem>


        <FormItem label="账号">
            <Input  v-model="sevo.account" clearable placeholder="请输入账号" style="width: 150px" />
       </FormItem>
         <FormItem label="电话">
            <Input  v-model="sevo.phone" clearable placeholder="请输入电话" style="width: 150px" />
       </FormItem> -->
        <Button type="primary" class="search-bt" @click="findAllRole"  icon="ios-search">查询</Button>
        <Button type="primary"  class="add" icon="md-add" @click="addModal=true">添加</Button>
       </Form>

      </div>
      <br />
      <Table
        :max-height="maxHeight"
        border
        stripe
        :columns="columns"
        :data="roleList"
      ></Table>
      <br />
      <Page
            :total="total"
            @on-change="changePage"
            @on-page-size-change="changePageNum"
            show-sizer
            show-elevator />
    </div>
     <Modal
        v-model="addModal"
        title="新增"
        :footer-hide="true"
         >
         <Form ref="savo"  :model="savo" :rules="rulesavo" :label-width="80">
        <FormItem label="角色名称" prop="roleName">
            <Input type="text" v-model="savo.roleName"></Input>
        </FormItem>
        <FormItem label="角色描述" prop="remark">
            <Input type="text" v-model="savo.remark"></Input>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="add('savo')">保存</Button>
            <Button @click="resetSave('savo')" style="margin-left: 8px">重置</Button>
        </FormItem>
    </Form>

    </Modal>


      <!--修改-->
         <Modal
        v-model="updateModal"
        title="修改"
        :footer-hide="true">
       <Form ref="upvo"  :model="upvo" :rules="rulesavo" :label-width="80">
        <FormItem label="角色名称" prop="roleName">
            <Input type="text" v-model="upvo.roleName"></Input>
        </FormItem>
        <FormItem label="角色描述" prop="remark">
            <Input type="text" v-model="upvo.remark"></Input>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="updateACT('upvo')">保存</Button>
            <Button @click="resetSave('upvo')" style="margin-left: 8px">重置</Button>
        </FormItem>
    </Form>
    </Modal>
    <Modal
      v-model="perModal"
      title="权限列表"
      @on-visible-change="visibleChange"
      @on-ok="selectNode"
      @on-cancel="perModal = false"

    >
      <Tree
          ref="tree"
          :data="permissionTreeList"
          show-checkbox></Tree>

    </Modal>
      <!--删除-->
    <Modal v-model="delModal" width="360">
             <p slot="header" style="color:#f60;text-align:center">
            <Icon type="ios-information-circle"></Icon>
            <span>删除</span>
        </p>
        <div style="text-align:center">

            <p>确定要删除?</p>
        </div>
        <div slot="footer">
            <Button type="error" size="large" long :loading="delLoading" @click="del">删除</Button>
        </div>
    </Modal>
  </div>
</template>

<script>
import { findRoleList, saveRole, findTreeList, findTreeByRid, saveRolePermission, updateRole, delRole } from '@/api'
import { formatDate } from '@/utils/DateUtil.js'

export default {
  name: 'Role',
  data() {
    return {
      maxHeight: 800,
      addModal: false,
      updateModal: false,
      delModal: false,
      delLoading: false,
      pageIndex: 1,
      pageSize: 10,
      pageNum: 10,
      total: 100,
      sevo: {}, // 查询
      upvo: {}, // 修改
      savo: {// 保存
        cz: '',
        thick: '',
        wide: '',
        czPrice: '',
      },
      delRid: 0,
      rulesavo: {
        cz: [
              { required: true, message: '材质不能为空', trigger: 'blur' },
        ],
         thick: [
              { required: true, message: '厚度不能为空', trigger: 'blur' },
        ],
         wide: [
              { required: true, message: '宽度不能为空', trigger: 'blur' },
        ],
         czPrice: [
              { required: true, message: '价格不能为空', trigger: 'blur' },
        ],
      },
      columns: [
           {
              type: 'index',
              width: 60,
              align: 'center',
            },
        {
          title: '角色名称',
          key: 'roleName',
          resizable: true,
        },
        {
          title: '角色描述',
          key: 'remark',
          resizable: true,
        },
        {
          title: '创建时间',
          key: 'createTime',
          resizable: true,
          render: (h, params) => h('div',
        	    formatDate(new Date(params.row.createTime), 'yyyy-MM-dd hh:mm')),
        },

        {
          title: '操作',
          key: 'action',
          width: 250,
          align: 'center',
          render: (h, params) => h('div', [
                h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.permissMotail(params)
                    },
                  },
                },
                '权限设置'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.update(params)
                    },
                  },
                },
                '修改'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.remove(params.row)
                    },
                  },
                },
                '删除'
              ),
            ]),
        },
      ],
      roleList: [],
      czList: [],
      thickList: [],
      wideList: [],
      permissionTreeList: [],
      perModal: false,
      rid: '',
    }
  },
  mounted() {
    // 设置表格高度
    const t = this
    t.maxHeight = window.innerHeight - 240
    this.findAllRole()
    // this.findPermissList();
    // this.findCzList();
    // this.findThickList();
    // this.findwideList();
  },
created() {

  },
methods: {
    findAllRole() {
      let t = this
      let url = ''
      url += '&pageIndex=' + t.pageIndex
      url += '&pageSize=' + t.pageSize
      if (t.sevo.userName) {
        url += '&roleName=' + t.sevo.roleName
      }
        findRoleList(url).then((res) => {
        t.roleList = res.data.list
        t.total = res.data.count
      })
    },
    updateACT() {
      let data = this.upvo
      if (!data.rid) {
        console.log('cid不存在')
        return
      }
    updateRole(data).then((res) => {
         this.findAllRole()
         this.$Message.success('操作成功')
         this.updateModal = false
      })
    },
    update(params) {
        let t = this
        console.log(params.row)
        t.upvo = params.row
        t.updateModal = true
    },
    permissMotail(params) {
      let t = this
      t.perModal = true
      let url = 'rid=' + params.row.rid
      // this.findPermissList(rid);
      let rid = params.row.rid
      t.rid = rid
         findTreeByRid(url).then((res) => {
          let list = res.data
          let len = list.length
          for (let i = 0; i < len; i++) {
            let permission = list[i]
            let children = permission.permissionVoList
            let childrenList = new Array()
            if (children.length > 0) {
                 for (let j = 0; j < children.length; j++) {
                   console.log(children[j].checked)
                   let checked = children[j].checked === '1'
                   let disabled = children[j].disabled === '1'
                   let selected = children[j].selected === '1'
                  //  console.log(checked)
                   let o = {
                        title: children[j].text || children[j].permissionName,
                        expand: true,
                        selected,
                        checked,
                        disabled,
                        pid: children[j].pid,
                        parantId: children[j].parantId,
                    }
                    childrenList.push(o)
                 }
            }
            let obj = {
                title: permission.text || permission.permissionName,
                expand: true,
                selected: permission.selected,
                disabled: permission.disabled,
                pid: permission.pid,
                parantId: permission.parantId,
                children: childrenList,
            }
            if (childrenList.length == 0 && permission.checked === '1') {
              obj.checked = true
            }
            t.permissionTreeList.push(obj)
          }
      })
    },
    cancelUpdateModal() {
        let t = this
        t.updateModal = false
    },
    add(name) {
      this.$refs[name].validate((valid) => {
                if (valid) {
                    let sa = this.savo
                    saveRole(sa).then(() => {
                    this.savo = {}
                    this.$Message.success('操作成功')
                    this.findAllRole()
                    this.addModal = false
                    })
                }
        })
    },
    resetSave(name) {
      this.$refs[name].resetFields()
    },
    cancelAddModal() {
        this.savo = {}
    },
    remove(params) {
        let t = this
        t.delRid = params.rid
        t.delModal = true
    },
    del() {
        let t = this
        delRole(t.delRid).then(() => {
            this.$Message.success('操作成功')
             t.findAllRole()
        })
        t.delModal = false
    },
search() {
        this.findAllRole()
    },
changePage(v) {
      let t = this
      t.pageIndex = v
      t.findAllRole()
    },
changePageNum(v) {
      let t = this
      t.pageSize = v
      t.findAllRole()
    },
findPermissList(rid) {
      // console.log(rid)

    },
visibleChange(f) {
     if (!f) {
       this.permissionTreeList = []
     }
    },
selectNode() {
     let list = this.$refs.tree.getCheckedAndIndeterminateNodes()
     let pidArr = new Array()
     for (let i = 0; i < list.length; i++) {
       let note = list[i]
       pidArr.push(note.pid)
     }
     console.log(this.rid)
     let data = {
          rid: this.rid,
          pids: pidArr.toString(),
     }

     saveRolePermission(data).then((res) => {
        console.log(res)
     })
    },
  },
}
</script>

<style scoped>
.add{
    position: absolute;
    right: 40px;
}
.search-bt{
      position: absolute;
      right: 140px;
}
.query-c{
  height: 30px;
}
</style>